<template>
    <div class="card card-outline card-warning">
        <div class="card-header">
            <h3 class="card-title">
                <i class="fas fa-address-card mr-2"></i>
                {{ $t('general.perjudicados') }}
            </h3>
            <div class="card-tools">
                <button type="button" class="btn btn-block btn-primary btn-xs" dat>Añadir</button>
            </div>
        </div><!-- /.card-header -->
        <div class="card-body p-0">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>{{ $t('general.nombre') }}</th>
                        <th>{{ $t('general.direccion') }}</th>
                        <th style="width: 40px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Ataulfo</td>
                        <td>Calle Colón, 13-5</td>
                        <td><button class="btn btn-warning btn-xs">{{ $t('general.editar') }}</button></td>
                    </tr>
                </tbody>
            </table>
                      

            
        </div>
    </div>
    
     <servicio-datos-perjudicados-perjudicado />                           
    
</template>
<script>  

import serviciodatosperjudicadosperjudicado from './perjudicados_form.vue';

export default({
    setup() {     
    },
    components: {
        'servicio-datos-perjudicados-perjudicado': serviciodatosperjudicadosperjudicado,
    },
})


</script>